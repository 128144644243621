<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        PG사관리
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              @click="getList"
              color="primary"
              :loading="loading"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <v-btn
        depressed
        color="primary"
        @click="dialog.show = true"
      >
        PG사등록
      </v-btn>
    </div>
    <v-progress-linear
      v-if="loading === true"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-simple-table class="mt-10" v-else-if="loading === false && list.length > 0">
      <template #default>
        <tbody>
          <tr
            v-for="item in list"
            :key="item.no"
          >
            <td>
              {{ item.name }}
            </td>
            <td class="text-right">
              <v-btn
                depressed
                :color="item.disabled === false ? 'success' : 'error'"
                class="ml-3 pr-3"
                @click="disableConfirmOpen(item.no, item.name, item.disabled)"
              >
                {{ item.disabled === false ? '활성' : '비활성' }}
                <v-icon class="ml-1">
                  mdi-swap-horizontal
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="empty-msg"
      v-else-if="loading === false && list.length === 0"
    >등록된 PG사가 없습니다.</div>
    <v-dialog
      v-model="dialog.show"
      scrollable
      max-width="400"
      @click:outside="init"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
        >
          <span class="text-h5">PG사등록</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            class="pb-0 pt-4"
            v-model="dialog.title"
            solo
            outlined
            dense
            flat
            placeholder="PG사 이름"
            :rules="[titleRule]"
            autofocus
          />
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="registPG"
            :disabled="dialog.title.length === 0 || dialog.title.length > 20"
          >
            생성
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'Pg',
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    list: [],
    updateName: '',
    dialog: {
      show: false,
      title: '',
    },
    titleRule: (value) => {
      const test = value.length < 20 && value.length > 0;
      return test || 'PG사 이름은 1~20자여야 합니다.';
    },
    loading: false,
  }),
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.dialog.show = false;
      this.dialog.title = '';
    },
    registPG() {
      this.$socket.emit('pg.add', { name: this.dialog.title, type: 1 }, (resp) => {
        if (resp.result === 'success') {
          this.alert(['info', 'PG사가 등록되었습니다.']);
          this.init();
          this.getList();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getList() {
      this.loading = true;
      this.$socket.emit('pg.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [{ condition: 'eq', column: 'type', value: 1 }],
      }, (resp) => {
        if (resp.result === 'success') {
          this.list = resp.items;
          this.loading = false;
        } else {
          this.confirm({ show: false });
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    updatePG(no) {
      if (this.updateName.length <= 0 || this.updateName.length > 20) {
        this.alert(['error', 'PG사 이름은 1~20자여야 합니다.']);
      } else {
        this.$socket.emit('pg.update', { no, name: this.updateName }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['info', '수정되었습니다.']);
            this.getList();
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      }
    },
    disableConfirmOpen(no, name, disabled) {
      const msg = disabled === true ? '활성화' : '비활성화';
      const func = () => {
        this.$socket.emit('pg.update', { no, disabled: !disabled }, (resp) => {
          if (resp.result === 'success') {
            this.confirm({ show: false });
            this.alert(['info', `${msg}되었습니다.`]);
            this.getList();
          } else {
            this.confirm({ show: false });
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      };
      if (disabled === true) {
        func();
      } else {
        this.confirm({
          show: true,
          func,
          msg: `정말 비활성화할까요? (PG사명: ${name})`,
          btnText: '비활성화',
          color: 'error',
        });
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getList();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.text-input {
  background-color: #fff;
  padding: 5px;
  padding-right: 8px;
  padding-top:2px;
  width: 280px;
  display: flex;
  align-items: center;
}
.empty-msg {
  padding: 20px 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
}
</style>
